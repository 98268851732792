<template>
    <vue-draggable-resizable :h="400" :w="300">
        <v-toolbar dense>
            <v-toolbar-title>{{ $BlitzIt.navigation.findDisplayName(manyNavigation) }}</v-toolbar-title>
            <v-spacer />
            <v-btn
                small
                @click.stop="showEmpties = !showEmpties">
                {{ showEmpties ? 'Others' : 'Empties' }}
            </v-btn>
            <v-btn
                v-if="canClose"
                @click="close"
                icon
                small
                title="Close">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <!-- style="opacity: 0.97" -->
        <v-card
            height="100%"
            width="100%"
            class="overflow-y-auto">
            <v-text-field
                flat
                dense
                hide-details
                hide-no-data
                solo-inverted
                small
                single-line
                append-icon="mdi-close"
                label="Find"
                v-model="searchInput"
                @click:append="searchInput = null" />
            <drop @drop="remove" style="height: 100%">
                <v-card-text class="pa-0" full-width>
                    <v-list dense>
                        <v-list-item-group>
                            <template v-for="item in filteredItems">
                                <drag
                                    :key="item.id"
                                    :data="item">
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getNestedValue(item.manyItem, manyText) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </drag>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </drop>
            <v-overlay :value="error != null" absolute class="text-center" opacity="0.9">
                <v-icon size="50">mdi-alert</v-icon>
                <!-- <v-progress-circular indeterminate size="64" /> -->
                <p>{{ error }}</p>
            </v-overlay>
        </v-card>
        <v-overlay :value="loadingMsg != null" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>{{ loadingMsg }}</p>
        </v-overlay>
    </vue-draggable-resizable>
</template>

<script>
import { firstBy } from 'thenby';
import { Drag, Drop } from "vue-easy-dnd";

export default {
    name: 'BT-Board-Supply-List',
    components: {
        VueDraggableResizable: () => import('vue-draggable-resizable'),
        Drag,
        Drop
    },
    data: function() {
        return {
            allLinks: [],
            asyncItems: [], //{ manyItem: {}, links: [] }
            error: null,
            isAllLoaded: false, //whether all links have been pulled (for showing many items that have no links at all)
            isLinksAllLoaded: false, //for many to many
            loadedSingleIDs: [], //list of what links with singleIDs have been searched for
            loadingMsg: null,
            msg: null,
            searchInput: null,
            showEmpties: false,
        }
    },
    async mounted() {
        await this.pullItems();
    },
    props: {
        canClose: {
            type: Boolean,
            default: true
        },
        canInsert: {
            type: Boolean,
            default: true
        },

        itemProperties: {
            type: Array,
            default: null
        },
        linkNavigation: {
            type: String,
            default: null
        },
        linkGetURL: {
            type: String,
            default: '/get/Links/{id}' //{id}
        },
        linkParams: {
            type: Object,
            default: null
        },
        linkPostURL: {
            type: String,
            default: '/post/Links/Add'
        },
        linkRemoveByDelete: { //when removing a link - whether to post or delete
            type: Boolean,
            default: false
        },
        linkRemoveURL: {
            type: String,
            default: '/post/Links/Remove'
        },
        linkRemoveToggle: {
            type: Object,
            default: null
        },
        manyNavigation: {
            type: String,
            default: null
        },
        manyProp: {
            type: String,
            default: null
        },
        manyText: {
            type: String,
            default: 'text'
        },
        manyToMany: {
            type: Boolean,
            default: false
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        searchProps: {
            type: Array,
            default: null
        },
        singleID: { //order slot id value
            type: String,
            default: null
        },
        singleProp: { //order slot prop name in link or many item
            type: String,
            default: null
        },
        sortProp: {
            type: String,
            default: null
        }
    },
    watch: {
        linkRemoveToggle: function(val) {
            if (val != null) {
                this.removeLink(val);
            }
        },
        refreshToggle: function() {
            this.pullItems(false);
        },
        showEmpties: function() {
            console.log('toggle show empties');
            this.pullItems();
        },
        singleID: function() {
            this.pullItems();
        }
    },
    computed: {
        filteredItems() {
            var r = this.asyncItems;

            if (this.searchInput != null) {
                r = r.filter(x => this.hasSearch(x.manyItem, this.searchInput, this.searchProps));
            }

            if (this.singleProp != null) {
                if (this.showEmpties) {
                    if (this.manyToMany) {
                        r = r.filter(x => !this.isLengthyArray(x.links));
                    }
                    else {
                        r = r.filter(x => x.manyItem[this.singleProp] == null);
                    }
                }
                else if (this.singleID != null) {
                    //filter out that are linked to this id
                    if (this.manyToMany) {
                        r = r.filter(x => !this.isLengthyArray(x.links) || !x.links.some(z => z[this.singleProp] == this.singleID));
                    }
                    else {
                        r = r.filter(x => x.manyItem[this.singleProp] != this.singleID);
                    }
                }
            }

            if (this.sortProp != null) {
                r.sort(firstBy(z => this.getNestedValue(z.manyItem, this.sortProp)));
            }

            return r;
        },
    },
    methods: {
        close() {
            this.$emit('close', {
                navigation: this.manyNavigation
            });
        },
        getParamObj() {
            var paramObj = {};

            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            return paramObj;
        },
        async removeLink({ manyID, singleID }) {
            if (this.manyToMany) {
                var linkInd = this.allLinks.findIndex(z => z[this.singleProp] == singleID && z[this.manyProp] == manyID);
                console.log(`link ind: ${linkInd}`);
                if (linkInd >= 0) {
                    this.allLinks.splice(linkInd, 1);
                }
            }

            var many = this.asyncItems.find(z => z.manyItem.id == manyID);
            if (many != null) {
                linkInd = many.links.findIndex(z => z[this.singleProp] == singleID && z[this.manyProp] == manyID);
                if (linkInd >= 0) {
                    many.links.splice(linkInd, 1);
                }
            }
        },
        async remove(d) {
            if (!this.canInsert) {
                return;
            }
            
            var item = d.data;

            if (this.manyToMany) {
                try {
                    item.loadingMsg = 'Loading';

                    var linkInd = this.allLinks.findIndex(z => z[this.singleProp] == this.singleID && z[this.manyProp] == item.manyItem.id);
                    if (linkInd >= 0) {
                        console.log('linkInd');
                        console.log(linkInd);
                        var existingLink = this.allLinks[linkInd];
                        if (this.linkRemoveByDelete) {
                            await this.$BlitzIt.api.delete(this.linkNavigation, existingLink, null, this.linkRemoveURL);
                        }
                        else {
                            await this.$BlitzIt.api.post(this.linkNavigation, existingLink, null, this.linkRemoveURL);
                        }
                        this.allLinks.splice(linkInd, 1);

                        linkInd = item.links.findIndex(z => z[this.singleProp] == this.singleID && z[this.manyProp] == d.data.manyItem.id);
                        if (linkInd >= 0) {
                            item.links.splice(linkInd, 1);
                        }

                        var many = this.asyncItems.find(z => z.manyItem.id == item.manyItem.id);
                        console.log('many');
                        console.log(many);
                        if (many != null) {
                            linkInd = many.links.findIndex(z => z[this.singleProp] == this.singleID && z[this.manyProp] == item.manyItem.id);
                            if (linkInd >= 0) {
                                many.links.splice(linkInd, 1);
                            }
                        }
                    }

                    //item.link = null;
                }
                catch (err) {
                    item.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    item.errorMsg = null;
                    item.loadingMsg = null;
                }
            }
            else {
                item.manyItem[this.singleProp] = null;
                var patch = { id: item.manyItem.id };
                patch[this.singleProp] = null;

                await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch, null);
            }
        },
        async pullItems(refresh = false) {
            if (this.manyNavigation == null) {
                return;
            }
            
            if (this.manyToMany) {
                if (!this.showEmpties && this.loadedSingleIDs.some(z => z == this.singleID) || this.showEmpties && this.isLinksAllLoaded) {
                    if (!refresh) {
                        return;
                    }
                }
            }
            else {
                if (this.isAllLoaded && !refresh) {
                    return;
                }
            }

            try {
                this.loadingMsg = 'Pulling Data';
                
                var paramObj = this.getParamObj();
                var links = [];

                if (this.manyToMany) {
                    var getURL = this.linkGetURL;
                    if (getURL != null) {
                        getURL = getURL.replace('{id}', `${this.singleID}`);
                    }

                    links = await this.$BlitzIt.store.getAll(this.linkNavigation, this.linkParams, refresh, null, getURL);

                    links.forEach(link => {
                        if (!this.allLinks.some(z => z.id == link.id)) {
                            this.allLinks.push(link);
                        }
                    })

                    this.isLinksAllLoaded = true;
                }

                var res = await this.$BlitzIt.store.getAll(this.manyNavigation, paramObj, refresh, null, null);

                res = res.map(x => Object.assign({}, {
                    manyItem: x,
                    links: this.allLinks.filter(z => z[this.manyProp] == x.id),
                    loadingMsg: null,
                    errorMsg: null
                }));

                if (this.onPullSuccessAsync != null) {
                    res = await this.onPullSuccessAsync(res, refresh);
                }
                
                this.asyncItems = res;
                this.isAllLoaded = true;
            }
            catch (err) {
                this.error = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;

                if (this.singleID != null) {
                    this.loadedSingleIDs.push(this.singleID);
                }
            }
        },
    }
}
</script>